.flip-card {
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 50vh;
}

.flip-card.block .flip-card-inner {
  transform: none !important;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 50vh;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  display: block;
}

.flip-card-back {
  display: block;
  transform: rotateY(180deg);
  background-color: aliceblue;
  color: #000;
  position: relative;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s;
}

.flip-card:hover .image-overlay {
  opacity: 1;
}

.back-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 1rem;
  font-size: 1.6rem;
  font-family: "Lato", sans-serif;
}
/* 

  
  /* Responsive styles */
@media (max-width: 768px) {
  .flip-card {
    margin-bottom: 1rem;
  }
}
